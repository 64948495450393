import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
// import index from "../views/index/index.vue";
// import MobileIndex from "../views/index/MobileIndex.vue";

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/join",
    component: () => import("../views/shoubeicollection/join/join.vue"),
  },
  {
    path: "/merjoin",
    component: () => import("../views/shoubeicollection/merjoin/merjoin.vue"),
  },
];
//pc端的路由
export const pcRoutes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "Index",
    component: () => import("../views/index/index.vue"),
    redirect: "/homePage",
    children: [
      {
        path: "/homePage",
        name: "HomePage",
        component: () => import("../views/homePage/homePage.vue"),
      },
      {
        path: "/openPlatform",
        name: "OpenPlatform",
        component: () => import("../views/openPlatform/openPlatform.vue"),
      },
      {
        path: "/pos",
        name: "Pos",
        component: () => import("../views/pos/pos.vue"),
      },
      {
        path: "/dragonfly",
        name: "Dragonfly",
        component: () => import("../views/dragonfly/dragonfly.vue"),
      },
      {
        path: "/ruyi",
        name: "Ruyi",
        component: () => import("../views/ruyi/ruyi.vue"),
      },
      {
        path: "/yunlaba",
        name: "Yunlaba",
        component: () => import("../views/yunlaba/yunlaba.vue"),
      },
      {
        path: "/shoubeiplugin",
        name: "ShoubeiPlugin",
        component: () => import("../views/shoubeiplugin/shoubeiplugin.vue"),
      },
      {
        path: "/catering",
        name: "Catering",
        component: () => import("../views/catering/catering.vue"),
      },
      {
        path: "/xfb",
        name: "xfb",
        component: () => import("../views/xfb/index.vue"),
      },
      {
        path: "/lsb",
        name: "lsb",
        component: () => import("../views/lsb/index.vue"),
      },
      {
        path: "/sqb",
        name: "sqb",
        component: () => import("../views/sqb/index.vue"),
      },
      {
        path: "/yzt",
        name: "yzt",
        component: () => import("../views/yzt/index.vue"),
      },
      {
        path: "/retail",
        name: "Retail",
        component: () => import("../views/retail/retail.vue"),
      },
      {
        path: "/school",
        name: "School",
        component: () => import("../views/school/school.vue"),
      },
      {
        path: "/diandiantong",
        name: "Diandiantong",
        component: () => import("../views/diandiantong/diandiantong.vue"),
      },
      {
        path: "/aboutus",
        name: "aboutus",
        component: () => import("../views/aboutus/aboutus.vue"),
      },
      {
        path: "/devhistory",
        name: "devhistory",
        component: () => import("../views/devhistory/devhistory.vue"),
      },
      {
        path: "/contactus",
        name: "contactus",
        component: () => import("../views/contactus/contactus.vue"),
      },
      {
        path: "/devdocument",
        name: "devdocument",
        component: () => import("../views/devdocument/devdocument.vue"),
      },
      {
        path: "/download",
        name: "download",
        component: () => import("../views/download/download.vue"),
      },
      {
        path: "/group",
        name: "group",
        component: () => import("../views/group/group.vue"),
      },
      {
        path: "/dynamic",
        name: "dynamic",
        component: () => import("../views/dynamicDetail/dynamicDetail.vue"),
      },
      {
        path: "/certificate",
        name: "certificate",
        component: () => import("../views/certificate/certificate.vue"),
      },
      {
        path: "/record",
        name: "record",
        component: () => import("../views/record/record.vue"),
      },
      {
        path: "/giift",
        name: "giift",
        component: () => import("../views/giift/giift.vue"),
      },
      {
        path: "/digital",
        name: "digital",
        component: () => import("../views/digital/digital.vue"),
      },
      {
        path: "/report",
        name: "report",
        component: () => import("../views/report/report.vue"),
      },
      {
        path: "/speedcloud",
        name: "speedcloud",
        component: () => import("../views/speedcloud/speedcloud.vue"),
      },
      {
        path: "/paypass",
        name: "paypass",
        component: () => import("../views/paypass/paypass.vue"),
      },
      {
        path: "/teambuilding",
        name: "teambuilding",
        component: () => import("../views/teambuilding/teambuilding.vue"),
      },
      {
        path: "/construction",
        name: "Construction",
        component: () => import("../views/construction/construction.vue"),
      },
      {
        path: "/xiangtanuniversity",
        name: "xiangtanuniversity",
        component: () =>
          import("../views/xiangtanuniversity/xiangtanuniversity.vue"),
      },
    ],
  },
  // {
  //   path: "/posDetail",
  //   name: "PosDetail",
  //   component: () => import("../views/posDetail/posDetail.vue"),
  // },
];

//移动端设备路由
export const mobileRoutes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "IndexMobile",
    component: () => import("../views/index/MobileIndex.vue"),
    redirect: "/homePage",
    children: [
      {
        path: "/homePage",
        name: "HomePageMobile",
        component: () => import("../views/homePage/MobileHomePage.vue"),
      },
      {
        path: "/openPlatform",
        name: "OpenPlatformMobile",
        component: () => import("../views/openPlatform/openPlatformmobile.vue"),
      },
      {
        path: "/shoubeiplugin",
        name: "Shoubeipluginmobile",
        component: () =>
          import("../views/shoubeiplugin/shoubeipluginmobile.vue"),
      },
      {
        path: "/dragonfly",
        name: "dragonflymobile",
        component: () => import("../views/dragonfly/dragonflymobile.vue"),
      },
      {
        path: "/xfb",
        name: "xfbmobile",
        component: () => import("../views/xfb/mobileXfb.vue"),
      },
      {
        path: "/lsb",
        name: "lsbmobile",
        component: () => import("../views/lsb/mobilelsb.vue"),
      },
      {
        path: "/yzt",
        name: "yztmobile",
        component: () => import("../views/yzt/mobileyzt.vue"),
      },
      {
        path: "/sqb",
        name: "sqbmobile",
        component: () => import("../views/sqb/mobilesqb.vue"),
      },
      {
        path: "/yunlaba",
        name: "yunlabamobile",
        component: () => import("../views/yunlaba/yunlabamobile.vue"),
      },
      {
        path: "/pos",
        name: "posmobile",
        component: () => import("../views/pos/posmobile.vue"),
      },
      {
        path: "/ruyi",
        name: "ruyimobile",
        component: () => import("../views/ruyi/ruyimobile.vue"),
      },
      {
        path: "/catering",
        name: "cateringmobile",
        component: () => import("../views/catering/cateringmobile.vue"),
      },
      {
        path: "/retail",
        name: "retailmobile",
        component: () => import("../views/retail/retailmobile.vue"),
      },
      {
        path: "/school",
        name: "schoolmobile",
        component: () => import("../views/school/schoolmobile.vue"),
      },
      {
        path: "/diandiantong",
        name: "diandiantongmobile",
        component: () => import("../views/diandiantong/diandiantongmobile.vue"),
      },
      {
        path: "/group",
        name: "groupmobile",
        component: () => import("../views/group/groupmobile.vue"),
      },
      {
        path: "/aboutus",
        name: "aboutusmobile",
        component: () => import("../views/aboutus/aboutusmobile.vue"),
      },
      {
        path: "/devhistory",
        name: "devhistorymobile",
        component: () => import("../views/devhistory/devhistorymobile.vue"),
      },
      {
        path: "/contactus",
        name: "contactusmobile",
        component: () => import("../views/contactus/contactusmobile.vue"),
      },
      {
        path: "/devdocument",
        name: "devdocumentmobile",
        component: () => import("../views/devdocument/devdocumentmobile.vue"),
      },
      {
        path: "/download",
        name: "downloadmobile",
        component: () => import("../views/download/downloadmobile.vue"),
      },
      {
        path: "/dynamicDetail",
        name: "dynamicDetailmobile",
        component: () =>
          import("../views/dynamicDetail/dynamicDetailmobile.vue"),
      },
      {
        path: "/certificate",
        name: "certificatemobile",
        component: () => import("../views/certificate/certificatemobile.vue"),
      },
      {
        path: "/record",
        name: "recordmobile",
        component: () => import("../views/record/recordmobile.vue"),
      },
      {
        path: "/giift",
        name: "giiftmobile",
        component: () => import("../views/giift/giiftmobile.vue"),
      },
      {
        path: "/digital",
        name: "digitalmobile",
        component: () => import("../views/digital/digitalmobile.vue"),
      },
      {
        path: "/report",
        name: "reportmobile",
        component: () => import("../views/report/reportmobile.vue"),
      },
      {
        path: "/speedcloud",
        name: "speedcloudmobile",
        component: () => import("../views/speedcloud/speedcloudmobile.vue"),
      },
      {
        path: "/paypass",
        name: "paypassmobile",
        component: () => import("../views/paypass/paypassmobile.vue"),
      },
      {
        path: "/teambuilding",
        name: "teambuildingmobile",
        component: () => import("../views/teambuilding/teambuildingmobile.vue"),
      },
      {
        path: "/construction",
        name: "constructionmobile",
        component: () => import("../views/construction/constructionmobile.vue"),
      },
      {
        path: "/xiangtanuniversity",
        name: "xiangtanuniversitymobile",
        component: () =>
          import("../views/xiangtanuniversity/xiangtanuniversitymobile.vue"),
      },
      {
        path: "/productcenter",
        name: "productcenter",
        component: () => import("../views/productcenter/productcenter.vue"),
      },
    ],
  },
  {
    path: "/join",
    name: "Join",
    component: () => import("../views/shoubeicollection/join/join.vue"),
    redirect: "/join",
  },
];

const router = createRouter({
  scrollBehavior: () => ({ y: 0 }),
  history: createWebHistory("/"),
  // history: createWebHashHistory(),
  routes,
});

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
